import { Box, LinearProgress } from '@mui/material'
import { EmptyState } from './EmptyState'
import styled from '@emotion/styled'

export const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 1,
  borderRadius: 0,
}))

export const PageLayout = ({ isLoading, isEmpty, fill, emptyLabel, children, ...rest }) => {
  return (
    <Box {...rest}>
      {isLoading && <BorderLinearProgress />}
      <Box
        sx={{
          py: fill ? 0 : 2,
          px: fill ? 0 : 4,
          mt: isLoading ? '0px' : '1px',
        }}
      >
        {isEmpty ? (
          <EmptyState
            label={emptyLabel}
            sx={{
              minHeight: '600px',
            }}
          />
        ) : (
          children
        )}
      </Box>
    </Box>
  )
}

export const Container = ({ children, ...props }) => (
  <Box {...props} py={props.py || 2} px={props.px || 4}>
    {children}
  </Box>
)
