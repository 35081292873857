/**
 * Config
 */
const env = window.location.hostname.includes('serverless.com') ? 'prod' : 'dev'

/**
 * Dev
 */
const devConfig = {
  auth0: {
    domain: 'auth.serverless-dev.com',
    clientId: 'EMAtx5b2Bf3PB94c3pm9nrADxpFvyZcm',
    audience: 'https://serverlessdev.auth0.com/userinfo',
    scope: 'openid email_verified email profile name offline_access',
  },
  platform: {
    stage: 'dev',
    apiUrl: 'https://api.serverless-dev.com',
    coreApiUrl: 'https://core.serverless-dev.com/api',
    app: 'https://app.serverless-dev.com',
  },
  mui: {
    licenseKey:
      'e7163c4816a16be174ebb724c5c5590aTz04OTQ3MixFPTE3NDYxMTI4MTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  },
}

/**
 * Prod
 */
const prodConfig = {
  auth0: {
    domain: 'auth.serverless.com',
    clientId: 'YAHOG8papb1tkrLttNVoVOSs4YLOjrNj',
    audience: 'https://serverlessinc.auth0.com/userinfo',
    scope: 'openid email_verified email profile name offline_access',
  },
  platform: {
    stage: 'prod',
    apiUrl: 'https://api.serverless.com',
    coreApiUrl: 'https://core.serverless.com/api',
    app: 'https://app.serverless.com',
  },
  mui: {
    licenseKey:
      'e7163c4816a16be174ebb724c5c5590aTz04OTQ3MixFPTE3NDYxMTI4MTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  },
}

module.exports = env === 'prod' || process.env.REACT_APP_ENV === 'prod' ? prodConfig : devConfig
